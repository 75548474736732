* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root {
    height: 100%;
}

:root {
    --background: #060608;
    --color: #fafafa;
}

html,
body {
    width: 100%;
    height: 100%;
    font-family: Arial;
    background: var(--background);
}

.parent {
    height: 100%;
}

.navbar {
    display: flex;
    justify-content: center;
    margin-top: 2%;
}

.body-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background);
}

.container {
    color: var(--color);
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
}

.right {
    text-align: right;
    width: 100%;
}

.stack {
    display: grid;
    grid-template-columns: 1fr;
}

.stack span {
    font-weight: bold;
    grid-row-start: 1;
    grid-column-start: 1;
    font-size: 4rem;
    --stack-height: calc(100% / var(--stacks) - 1px);
    --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
    --clip-top: calc(var(--stack-height) * var(--index));
    --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
    clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
    animation: stack var(--time) cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
            calc(var(--index) * 120ms),
        glitch 2s ease infinite 2s alternate-reverse;
}

.stack span:nth-child(odd) {
    --glitch-translate: 8px;
}
.stack span:nth-child(even) {
    --glitch-translate: -8px;
}

@keyframes stack {
    0% {
        opacity: 0;
        transform: translateX(-50%);
        text-shadow: -2px 3px 0 greenyellow, 2px -3px 0 Fuchsia;
    }
    60% {
        opacity: 0.5;
        transform: translateX(50%);
    }
    80% {
        transform: none;
        opacity: 1;
        text-shadow: 2px -3px 0 greenyellow, -2px 3px 0 Fuchsia;
    }
    100% {
        text-shadow: none;
    }
}

@keyframes glitch {
    0% {
        text-shadow: -2px 3px 0 greenyellow, 2px -3px 0 Fuchsia;
        transform: translate(var(--glitch-translate));
    }
    2% {
        text-shadow: 2px -3px 0 greenyellow, -2px 3px 0 Fuchsia;
    }
    4%,
    100% {
        text-shadow: none;
        transform: none;
    }
}
