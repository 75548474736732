.glitch-button {
    background-color: transparent;
    color: var(--color);
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    margin: auto;
    cursor: pointer;
}

.right {
    text-align: right;
    width: 100%;
}

.glitch-text {
    display: grid;
    grid-template-columns: 1fr;
}

.glitch-text span {
    font-weight: bold;
    grid-row-start: 1;
    grid-column-start: 1;
    font-size: 1rem;
    --stack-height: calc(100% / var(--stacks) - 1px);
    --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
    --clip-top: calc(var(--stack-height) * var(--index));
    --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
    clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
}

.glitch-text:hover span {
    animation: stack 500ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
            calc(var(--index) * 120ms),
        glitch 2s ease 2s alternate-reverse;
}

.glitch-text span:nth-child(odd) {
    --glitch-translate: 100px;
}
.glitch-text span:nth-child(even) {
    --glitch-translate: -100px;
}
